<template>
  <q-tr
    :props="data"
  >
    <q-td
      key="index"
      :props="data"
      auto-width
    >
        {{ data.rowIndex +  1 }}
    </q-td>

    <q-td
      key="customer_warehouse"
      :props="data"
      auto-width
    >
        <div v-if="data.row && data.row._embedded && data.row._embedded.warehouse">
            {{`${data.row._embedded.warehouse.name} (${data.row._embedded.warehouse.id})`}}
        </div>
    </q-td>

<!--    <q-td-->
<!--      key="shipped"-->
<!--      :props="data"-->
<!--      auto-width-->
<!--    >-->
<!--      {{shipped}}-->
<!--    </q-td>-->

    <q-td
      key="normal"
      :props="data"
      auto-width
    >
      {{ data.row.normal }}
    </q-td>

    <q-td
      key="booked"
      :props="data"
      auto-width
    >
      {{  data.row.booked }}
    </q-td>

    <q-td
      key="blocked"
      :props="data"
      auto-width
    >
      {{ data.row.blocked }}
    </q-td>

    <q-td
      key="total"
      :props="data"
      auto-width
    >
      {{ data.row.total }}
    </q-td>

  </q-tr>
</template>

<script>

export default {
  name: 'WarehouseListRow',
  props: {
    data: {
      type: Object,
      required: true,
      default() {
          return null
      }
    }
  }
}

</script>